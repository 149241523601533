import React from 'react';
import { Download } from 'lucide-react';

const ActionPanel = ({ onGenerate }) => {
  return (
    <div className="border rounded-lg bg-white shadow-sm p-4">
      <button 
        onClick={onGenerate}
        className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
      >
        <Download className="w-5 h-5 mr-2" />
        Generate and Download LICENCE.pay
      </button>
    </div>
  );
};

export default ActionPanel;