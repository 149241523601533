import React from 'react';
import CollapsibleSection from './CollapsibleSection';

const LicenseBuilder = ({ licenseData, setLicenseData }) => {
  return (
    <div className="space-y-4">
      <CollapsibleSection title="Project Details">
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Project Name</label>
            <input
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="Enter your project name"
              value={licenseData.projectDetails?.name || ''}
              onChange={(e) =>
                setLicenseData({
                  ...licenseData,
                  projectDetails: { ...licenseData.projectDetails, name: e.target.value }
                })
              }
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">GitHub Repository</label>
            <input
              type="url"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="https://github.com/username/repository"
              value={licenseData.projectDetails?.githubUrl || ''}
              onChange={(e) =>
                setLicenseData({
                  ...licenseData,
                  projectDetails: { ...licenseData.projectDetails, githubUrl: e.target.value }
                })
              }
            />
          </div>
        </div>
      </CollapsibleSection>

      <CollapsibleSection title="Payment Terms">
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Payment Model</label>
            <select
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              value={licenseData.paymentTerms.model}
              onChange={(e) =>
                setLicenseData({
                  ...licenseData,
                  paymentTerms: { ...licenseData.paymentTerms, model: e.target.value }
                })
              }
            >
              <option value="">Select a model</option>
              <option value="pay-per-use">Pay per Use</option>
              <option value="subscription (monthly)">Subscription (monthly)</option>
              <option value="one-time">One-time Payment</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Amount</label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                type="number"
                className="flex-1 rounded-l-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
                value={licenseData.paymentTerms.amount}
                onChange={(e) =>
                  setLicenseData({
                    ...licenseData,
                    paymentTerms: { ...licenseData.paymentTerms, amount: e.target.value }
                  })
                }
              />
              <select
                className="rounded-r-md border-l-0 border-gray-300 focus:border-blue-500 focus:ring-blue-500"
                value={licenseData.paymentTerms.currency}
                onChange={(e) =>
                  setLicenseData({
                    ...licenseData,
                    paymentTerms: { ...licenseData.paymentTerms, currency: e.target.value }
                  })
                }
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
              </select>
            </div>
          </div>
        </div>
      </CollapsibleSection>

      <CollapsibleSection title="Payment Methods">
        <div className="space-y-4">
          <div className="flex items-center">
            <input
              type="radio"
              id="opencommerce"
              name="paymentMethod"
              checked={licenseData.paymentMethods.opencommerce}
              onChange={(e) => {
                const randomId = Math.random().toString(16).substring(2, 8).toUpperCase();
                setLicenseData({
                  ...licenseData,
                  paymentMethods: { 
                    opencommerce: true,
                    stablecoins: false,
                    licenseId: randomId,
                    AccountAddress: '' // Clear stablecoins address when switching
                  }
                })
              }}
              className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <label htmlFor="opencommerce" className="ml-2 text-sm text-gray-700">
              OpenCommerce (we'll collect the payment for you)
            </label>
          </div>

          {licenseData.paymentMethods.opencommerce && (
            <div className="ml-6 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">License ID</label>
                <input
                  type="text"
                  readOnly
                  className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100"
                  value={licenseData.paymentMethods.licenseId || ''}
                />
              </div>
            </div>
          )}

          <div className="flex items-center">
            <input
              type="radio"
              id="stablecoins"
              name="paymentMethod"
              checked={licenseData.paymentMethods.stablecoins}
              onChange={(e) =>
                setLicenseData({
                  ...licenseData,
                  paymentMethods: { 
                    opencommerce: false,
                    stablecoins: true,
                    licenseId: null // Clear license ID when switching
                  }
                })
              }
              className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <label htmlFor="stablecoins" className="ml-2 text-sm text-gray-700">
              Stablecoins (generate an address with the <a 
                href="https://github.com/OpenCommerce-xyz/LICENCE.pay"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800"
              >OpenCommerce SDK</a>)
            </label>
          </div>

          {licenseData.paymentMethods.stablecoins && (
            <div className="ml-6 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Network</label>
                <input
                  type="text"
                  readOnly
                  className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100"
                  value="Base"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Account Address</label>
                <input
                  type="text"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Enter your account address"
                  value={licenseData.paymentMethods.AccountAddress}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      paymentMethods: { 
                        ...licenseData.paymentMethods, 
                        AccountAddress: e.target.value 
                      }
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
      </CollapsibleSection>

      <CollapsibleSection title="Usage Rights">
        <div className="space-y-3">
          {Object.entries(licenseData.usageRights).map(([key, value]) => (
            <div key={key} className="flex items-center">
              <input
                type="checkbox"
                id={key}
                checked={value}
                onChange={(e) =>
                  setLicenseData({
                    ...licenseData,
                    usageRights: { ...licenseData.usageRights, [key]: e.target.checked }
                  })
                }
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <label htmlFor={key} className="ml-2 text-sm text-gray-700">
                {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
              </label>
            </div>
          ))}
        </div>
      </CollapsibleSection>

      <CollapsibleSection title="Restrictions">
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Geographical Restrictions</label>
            <input
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="e.g., EU, North America"
              value={licenseData.restrictions.geographical}
              onChange={(e) =>
                setLicenseData({
                  ...licenseData,
                  restrictions: { ...licenseData.restrictions, geographical: e.target.value }
                })
              }
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Usage Limits</label>
            <input
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="e.g., 1000 API calls per month"
              value={licenseData.restrictions.usageLimits}
              onChange={(e) =>
                setLicenseData({
                  ...licenseData,
                  restrictions: { ...licenseData.restrictions, usageLimits: e.target.value }
                })
              }
            />
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="competitiveUse"
              checked={licenseData.restrictions.competitiveUse}
              onChange={(e) =>
                setLicenseData({
                  ...licenseData,
                  restrictions: { ...licenseData.restrictions, competitiveUse: e.target.checked }
                })
              }
              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <label htmlFor="competitiveUse" className="ml-2 text-sm text-gray-700">
              Restrict Competitive Use
            </label>
          </div>
        </div>
      </CollapsibleSection>

      <CollapsibleSection title="Additional Terms">
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Custom Clauses</label>
            <textarea
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              rows="4"
              placeholder="Enter any additional terms or custom clauses for your license agreement"
              value={licenseData.additionalTerms}
              onChange={(e) =>
                setLicenseData({
                  ...licenseData,
                  additionalTerms: e.target.value
                })
              }
            />
          </div>
        </div>
      </CollapsibleSection>
    </div>
  );
};

export default LicenseBuilder;