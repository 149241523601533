import React from 'react';

const PreviewPanel = ({ licenseData }) => {
  const formattedData = {
    projectDetails: {
      name: licenseData.projectDetails?.name || '',
      githubUrl: licenseData.projectDetails?.githubUrl || ''
    },
    paymentTerms: licenseData.paymentTerms,
    paymentMethods: licenseData.paymentMethods,
    usageRights: licenseData.usageRights,
    restrictions: licenseData.restrictions,
    additionalTerms: licenseData.additionalTerms
  };

  return (
    <div className="border rounded-lg bg-white shadow-sm p-4">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Preview</h3>
      <pre className="bg-gray-50 p-4 rounded-md overflow-x-auto text-sm">
        <code>{JSON.stringify(formattedData, null, 2)}</code>
      </pre>
    </div>
  );
};

export default PreviewPanel;