import React, { useState } from 'react';
import { ExternalLink, Info, ArrowRight, Terminal, FileCode, Github, MessageCircle } from 'lucide-react';
import LicenseBuilder from './components/LicenseBuilder';
import PreviewPanel from './components/PreviewPanel';
import ActionPanel from './components/ActionPanel';

const App = () => {
  const [showLicenseBuilder, setShowLicenseBuilder] = useState(false);
  const [licenseData, setLicenseData] = useState({
    paymentTerms: {
      model: '',
      amount: '',
      currency: 'USD',
    },
    usageRights: {
      commercial: false,
      personal: false,
      Training: true,
      Inference: true,
      redistribution: false,
      modification: false
    },
    restrictions: {
      geographical: '',
      usageLimits: '',
      competitiveUse: false
    },
    paymentMethods: {
      stablecoins: false,
      network: '',
      AccountAddress: ''
    }
  });

  const generateLicenseFile = () => {
    // Validate account address if stablecoins selected
    if (licenseData.paymentMethods.stablecoins && !licenseData.paymentMethods.AccountAddress) {
      alert('Please enter a account address');
      return;
    }

    const content = `# LICENCE.pay\n\n${JSON.stringify(licenseData, null, 2)}`;
    const blob = new Blob([content], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'LICENCE.pay';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <div className="flex-shrink-0">
              <h1 
                className="text-xl font-bold text-gray-900 cursor-pointer" 
                style={{ fontFamily: 'Tomorrow, sans-serif' }}
                onClick={() => setShowLicenseBuilder(false)}
              >
                OpenCommerce
              </h1>
            </div>
            <div className="flex gap-2">
              <a
                href="https://t.me/+IMW-8lRwuSZkZGU0"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center p-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700"
              >
                <MessageCircle className="w-5 h-5" />
              </a>
              <a
                href="https://github.com/OpenCommerce-xyz/LicencePay"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center p-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700"
              >
                <Github className="w-5 h-5" />
              </a>
              <a
                href="https://www.opencommerce.xyz"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center p-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700"
              >
                <Info className="w-5 h-5" />
              </a>
            </div>
          </div>
        </div>
      </nav>

      <main>
        {showLicenseBuilder ? (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              <div className="space-y-6">
                <LicenseBuilder licenseData={licenseData} setLicenseData={setLicenseData} />
              </div>
              <div className="lg:sticky lg:top-8 space-y-6">
                <PreviewPanel licenseData={licenseData} />
                <ActionPanel onGenerate={generateLicenseFile} />
              </div>
            </div>
          </div>
        ) : (
          <div className="min-h-screen flex flex-col items-center justify-center px-4 py-12">
            <div className="text-center max-w-3xl mx-auto mb-16">
              <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-indigo-600 to-[#4F46E5] bg-clip-text text-transparent">
                LICENCE.pay Generator
              </h1>
              <p className="text-xl text-gray-700 mb-12 leading-relaxed">
                LICENCE.pay is a licensing format that enables monetization of open-source software 
                strictly for AI usage. It allows developers to set clear terms for code usage by AI 
                while ensuring fair compensation.
              </p>
              
              <div className="grid md:grid-cols-3 gap-10 mb-12">
                {/* Step 1 */}
                <div className="relative bg-gradient-to-b from-white to-blue-50/30 backdrop-blur-sm rounded-xl p-8 border border-indigo-100/20 shadow-sm hover:shadow-md transition-all duration-200 flex flex-col min-h-[280px]">
                  <div className="absolute -top-3 -left-3">
                    <div className="h-14 w-14 rounded-full bg-[#4F46E5] text-white flex items-center justify-center font-bold text-2xl shadow-sm">
                      1
                    </div>
                  </div>
                  <div className="mb-6 flex justify-end">
                    <Terminal className="h-10 w-10 text-[#4F46E5]" />
                  </div>
                  <h3 className="text-xl font-semibold mb-4 text-gray-900">Use OpenCommerce SDK</h3>
                  <p className="text-gray-600 mb-4 flex-grow">
                    Integrate our SDK to start receiving payments from AI agents
                  </p>
                  <div className="mt-auto">
                    <a
                      href="https://github.com/OpenCommerce-xyz/LicencePay"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center text-sm text-[#4F46E5] font-medium hover:text-indigo-700 cursor-pointer"
                    >
                      60-second setup <ArrowRight className="h-4 w-4 ml-1" />
                    </a>
                  </div>
                </div>

                {/* Step 2 */}
                <div className="relative bg-gradient-to-b from-white to-blue-50/30 backdrop-blur-sm rounded-xl p-8 border border-indigo-100/20 shadow-sm hover:shadow-md transition-all duration-200 flex flex-col min-h-[280px]">
                  <div className="absolute -top-3 -left-3">
                    <div className="h-14 w-14 rounded-full bg-[#4F46E5] text-white flex items-center justify-center font-bold text-2xl shadow-sm">
                      2
                    </div>
                  </div>
                  <div className="mb-6 flex justify-end">
                    <FileCode className="h-10 w-10 text-[#4F46E5]" />
                  </div>
                  <h3 className="text-xl font-semibold mb-4 text-gray-900">Create LICENCE.pay</h3>
                  <p className="text-gray-600 mb-4 flex-grow">
                    Use our wizard to generate your custom licence file
                  </p>
                  <div className="mt-auto">
                    <button
                      onClick={() => setShowLicenseBuilder(true)}
                      className="inline-flex items-center text-sm text-[#4F46E5] font-medium hover:text-indigo-700"
                    >
                      Start now <ArrowRight className="h-4 w-4 ml-1" />
                    </button>
                  </div>
                </div>

                {/* Step 3 */}
                <div className="relative bg-gradient-to-b from-white to-blue-50/30 backdrop-blur-sm rounded-xl p-8 border border-indigo-100/20 shadow-sm hover:shadow-md transition-all duration-200 flex flex-col min-h-[280px]">
                  <div className="absolute -top-3 -left-3">
                    <div className="h-14 w-14 rounded-full bg-[#4F46E5] text-white flex items-center justify-center font-bold text-2xl shadow-sm">
                      3
                    </div>
                  </div>
                  <div className="mb-6 flex justify-end">
                    <Github className="h-10 w-10 text-[#4F46E5]" />
                  </div>
                  <h3 className="text-xl font-semibold mb-4 text-gray-900">Add to GitHub</h3>
                  <p className="text-gray-600 mb-4 flex-grow">
                    Place LICENCE.pay in your repository root
                  </p>
                  <div className="mt-auto">
                    <span className="text-sm text-gray-500 font-medium">
                      Ready to use
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-6">
                <button
                  onClick={() => setShowLicenseBuilder(true)}
                  className="inline-flex items-center px-8 py-4 text-lg font-semibold rounded-lg text-white bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 transition-all shadow-lg hover:shadow-xl"
                >
                  Create LICENCE.pay
                  <ArrowRight className="ml-2 w-5 h-5" />
                </button>

                <div>
                  <a
                    href="https://github.com/OpenCommerce-xyz/LicencePay"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-700 font-medium inline-flex items-center"
                  >
                    View Documentation
                    <ExternalLink className="ml-1 w-4 h-4" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default App;